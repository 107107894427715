<template>
  <div class="main-content" style="
    padding-bottom: 10vh !important
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      width: 100%;
      background-position: center;
    ">
      <div class="dashboard-banner position-relative">
          <div class="d-flex justify-content-center align-items-end">
            <!-- <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("dashboard") }}</h4> -->
          </div>
          <div class="position-absolute h-100 w-100" style="background: #d2d2d24d" v-if="isLoading">
          <div class="px-2 py-1 rounded" style="
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #add8e6;
              ">
          </div>
          </div>
      </div>
      <div class="m-3 mx-3 mt-4">
          <div class="header">
              <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack">
              <h1 class="main_text text text_main_title">{{ $t("bonus_record") }}</h1>
              <!-- <i class="fa fa-history info-icon" aria-hidden="true" @click="goHistory"></i> -->
              <!-- <img src="../../../assets/images/etnex_buy/question.png" alt="Info" class="info-icon"> -->
          </div>
          <div class="container my-5" style="padding-bottom: 15px;">
            <div class="row" style="margin-top: -5rem;">
              <div class="col-12 mb-3 pt-3">
                <div class="card" style="background-color: transparent !important;"  @click="$router.push('/web/bonus/newbonusRecord?type=static_bonus')">
                    <b-row class="gx-1">
                      <b-col cols="12">
                        <div class="card-container-1 back_one black-dark-blue-card">
                          <div class="card-content">
                            <!-- <img src="../../../assets/images/latrader_bonus/one.png" class="card-icon mr-3"> -->
                            <span class="card-title">{{ $t("static_bonus") }}</span>
                            <!-- <img src="../../../assets/images/latrader_bonus/arrow.png" class="card-arrow ml-auto" @click="$router.push('/web/bonus/newbonusRecord?type=sponsor_bonus')"> -->
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                </div>
              </div>
            </div>
          </div>
          <div class="container my-5" style="padding-bottom: 15px;">
            <div class="row" style="margin-top: -5rem;">
              <div class="col-12 mb-3 pt-3">
                <div class="card" style="background-color: transparent !important;"  @click="$router.push('/web/bonus/newbonusRecord?type=dynamic_bonus')">
                    <b-row class="gx-1">
                      <b-col cols="12">
                        <div class="card-container-1 back_one black-dark-blue-card">
                          <div class="card-content">
                            <!-- <img src="../../../assets/images/latrader_bonus/one.png" class="card-icon mr-3"> -->
                            <span class="card-title">{{ $t("dynamic_bonus") }}</span>
                            <!-- <img src="../../../assets/images/latrader_bonus/arrow.png" class="card-arrow ml-auto" @click="$router.push('/web/bonus/newbonusRecord?type=sponsor_bonus')"> -->
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                </div>
              </div>
            </div>
          </div>
          <div class="container my-5">
            <div class="row" style="margin-top: -5rem;">
              <div class="col-12 mb-3 pt-3">
                <div class="card" style="background-color: transparent !important;"  @click="$router.push('/web/bonus/newbonusRecord?type=sponsor_bonus')">
                    <b-row class="gx-1">
                      <b-col cols="12">
                        <div class="card-container-1 back_one black-dark-blue-card">
                          <div class="card-content">
                            <!-- <img src="../../../assets/images/latrader_bonus/one.png" class="card-icon mr-3"> -->
                            <span class="card-title">{{ $t("sponsor_bonus") }}</span>
                            <!-- <img src="../../../assets/images/latrader_bonus/arrow.png" class="card-arrow ml-auto" @click="$router.push('/web/bonus/newbonusRecord?type=sponsor_bonus')"> -->
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      <Dialog ref="msg"></Dialog>
  </div>
</template>

<script>
import Dialog from "../../../components/dialog.vue";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["lang"]),
  },
  components: {
    Dialog,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  props: ["success"],
  methods: {
    goBack() {
        this.$router.go(-1)
    },
  },
  created() {
    
  },
};
</script>

<style scoped>
.gx-1 {
  margin-right: -5px;
  margin-left: -5px;
}
.gx-1 > .col,
.gx-1 > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}


.card-container-1 {
  position: relative;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  backdrop-filter: blur(20px);
  background: linear-gradient(90deg, #152331 0%, #000000 100%);
}

.back_one{
  /* background: url(../../../assets/images/latrader_bonus/one_back.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.back_two{
  /* background: url(../../../assets/images/latrader_bonus/two_back.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.back_three{
  /* background: url(../../../assets/images/latrader_bonus/three_back.png); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.card-content {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-icon {
  width: 65px;
  height: auto;
}

.card-title {
  margin: 0;
  color: #fff;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
}

.card-arrow {
  width: 16px;
  height: auto;
}

.header {
    display: flex;
    margin-top: 4rem !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 100px !important;
  }
  
  .info-icon{
    cursor: pointer;
    color: #fff;
    font-size: 18px;
  }
  
  .text_main_title{
    flex-grow: 1;
    text-align: center;
  }

  .text{
    color: var(--Main, #FFF);
    font-family: Poppins;
    font-style: normal;
  }
  
  .main_text{
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    margin-top: 7px;
  }

</style>